.system .page-header {
  padding: 50px 15px 0;
  height: 210px;
  background-image: url(../images/common/ph_bg_sp.jpg);
  background-size: cover;
}
.system__header {
  padding: 36px 15px 20px;
}
.system__header .inner {
  margin: 0 auto;
  padding: 0 0 10px;
  max-width: 1100px;
  border-bottom: 1px solid #C0DBEC;
}
.system__block {
  margin: 0;
  padding: 40px 15px;
}
.system__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}

@media screen and (min-width: 768px) {
  .system .page-header {
    padding: 80px 15px 0;
    height: 320px;
    background-image: url(../images/common/ph_bg.jpg);
    background-size: cover;
  }
  .system__header {
    padding: 80px 15px 28px;
  }
  .system__header .inner {
    padding: 0 0 17px;
  }
}
@media screen and (min-width: 992px) {
  .system__intro {
    padding: 75px 15px;
  }
  .system__intro p {
    text-align: center;
  }
}
.system__select {
  padding: 0 15px 20px;
}
.system__select__buttons {
  margin: 0 -15px 30px;
  padding: 0 15px;
  border-bottom: 1px solid #0072BC;
}
.system__select__buttons .flexBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
}
.system__select__buttons .flexItem {
  margin: 0;
  margin-bottom: -1px;
  padding: 0 5px;
  width: 33.3333%;
}
.system__select__buttons .selectBtn {
  padding: 14px 0;
  width: 100%;
  height: 100%;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1.54;
  letter-spacing: 0;
  background-color: #0072BC;
  border: 1px solid #0072BC;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
}
.system__select__buttons .selectBtn.on {
  color: #0072BC;
  background-color: #FFF;
  pointer-events: none;
}
.system__select__buttons .selectBtn:hover {
  color: #0072BC;
  background-color: #F1F6F9;
}
.system__select__contents__box {
  display: none;
}
.system__select__contents__box.open {
  display: block;
}

@media screen and (min-width: 768px) {
  .system__select__buttons {
    margin: 0 0 60px;
  }
}
@media screen and (min-width: 992px) {
  .system__select {
    margin: 0 0 60px;
  }
  .system__select__buttons {
    padding: 0 30px;
    position: relative;
  }
  .system__select__buttons::after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    border-bottom: 1px solid #0072BC;
    position: absolute;
    left: 50%;
    bottom: -1px;
    transform: translateX(-50%);
    z-index: -1;
  }
  .system__select__buttons .flexBox {
    margin: 0 -20px;
  }
  .system__select__buttons .flexItem {
    padding: 0 20px;
  }
  .system__select__buttons .selectBtn {
    padding: 17px 0;
    font-size: 18px;
    font-weight: 700;
  }
}
@media screen and (min-width: 992px) {
  .system__tender__map {
    margin: 50px auto 84px;
    position: relative;
  }
  .system__tender__map .img {
    margin: 0 auto;
    padding: 15px 0 160px;
    width: 100%;
    max-width: 845px;
    transform: translateX(-20px);
  }
  .system__tender__map__perf dl {
    width: 186px;
    position: absolute;
    border-radius: 6px;
    overflow: hidden;
  }
  .system__tender__map__perf dt {
    padding: 16px 21px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    cursor: pointer;
  }
  .system__tender__map__perf dt a {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
  }
  .system__tender__map__perf dt.open .icon_openclose {
    transform: translateY(-50%) rotate(45deg);
  }
  .system__tender__map__perf dt.open .icon_openclose::before, .system__tender__map__perf dt.open .icon_openclose::after {
    background-color: #707070 !important;
  }
  .system__tender__map__perf dd {
    display: none;
    padding: 0 22px 7px;
  }
  .system__tender__map__perf dd ul {
    margin-right: -30px;
  }
  .system__tender__map__perf dd ul::after {
    content: "";
    display: block;
    clear: both;
    height: 0;
  }
  .system__tender__map__perf dd li {
    float: left;
    margin-right: 22px;
    line-height: 2.2;
  }
  .system__tender__map__perf dd a {
    color: #333;
  }
  .system__tender__map__perf dl[data-group="1"] {
    width: 270px;
    left: 0;
    top: 0;
  }
  .system__tender__map__perf dl[data-group="1"] dt {
    padding: 16px 37px;
  }
  .system__tender__map__perf dl[data-group="2"] {
    width: 122px;
    left: 50%;
    top: 9.5%;
  }
  .system__tender__map__perf dl[data-group="2"] dt {
    padding: 16px 31px;
  }
  .system__tender__map__perf dl[data-group="3"] {
    left: 83%;
    top: 24.5%;
  }
  .system__tender__map__perf dl[data-group="4"] {
    left: 73.3%;
    top: 42.6%;
  }
  .system__tender__map__perf dl[data-group="5"] {
    width: 130px;
    left: 64%;
    top: 64.7%;
  }
  .system__tender__map__perf dl[data-group="6"] {
    left: 38.7%;
    top: 26.4%;
  }
  .system__tender__map__perf dl[data-group="7"] {
    width: 200px;
    left: 43%;
    top: 72%;
  }
  .system__tender__map__perf dl[data-group="8"] {
    width: 130px;
    left: 26.5%;
    top: 85.5%;
  }
  .system__tender__map__perf dl[data-group="9"] {
    left: 19.2%;
    top: 41%;
  }
  .system__tender__map__perf dl[data-group="10"] {
    left: 0%;
    top: 64%;
  }
  .system__tender__map__perf dl[data-group="11"] {
    width: 122px;
    left: 9.5%;
    top: 18.8%;
  }
}
.system__tender__contents {
  margin: 0 -15px;
  padding: 20px 0 0;
}

@media screen and (min-width: 992px) {
  .system__tender__contents {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 900px;
  }
}
.system__tender__group__wrap {
  overflow: hidden;
}
.system__tender__group__elem {
  margin: 10px -1px;
  border: 1px solid #0072BC;
}
.system__tender__group__elem dt {
  display: flex;
  align-items: center;
  padding: 0 40px 0 20px;
  width: 100%;
  height: 55px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.system__tender__group__elem dt.open .icon_openclose {
  transform: translateY(-50%) rotate(45deg);
}
.system__tender__group__elem dd {
  display: none;
  padding: 0;
  background-color: #EFEFEF;
}

#s1-2 .system__tender__group__elem dd {
  display: block;
}

#s1-3 .system__tender__group__elem dd {
  display: block;
}

@media screen and (min-width: 992px) {
  .system__tender__group__elem {
    display: none;
    margin: 50px 0;
    border: 0 !important;
  }
  .system__tender__group__elem dt {
    padding: 0 50px 0 30px;
    height: 50px;
    color: #fff !important;
    letter-spacing: 0.16em;
    background-color: #0072BC;
    pointer-events: none;
    display: none;
  }
  .system__tender__group__elem dt .icon_openclose {
    display: none;
  }
  .system__tender__group__elem dd {
    display: block !important;
    padding: 20px 0 0;
    background: none;
  }
  #s1-2 .system__tender__group__elem {
    display: block;
    margin: 0;
  }
  #s1-2 .system__tender__group__elem .pref_name {
    display: none;
  }
  #s1-3 .system__tender__group__elem {
    display: block;
    margin: 0;
  }
  #s1-3 .system__tender__group__elem .pref_name {
    display: none;
  }
}
.system__tender__group__pref__anchor {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
  background-color: #EFEFEF;
}
.system__tender__group__pref__anchor li {
  margin-right: 20px;
}
.system__tender__group__pref__anchor a {
  color: #333;
  font-size: 14px;
}

@media screen and (min-width: 992px) {
  .system__tender__group__pref__anchor {
    display: none;
  }
}
.system__tender__group__pref__block {
  margin: 20px 0;
  padding: 15px 15px;
  background-color: #fff;
}
.system__tender__group__pref__block:nth-of-type(1) {
  margin-top: 0;
}
.system__tender__group__pref__block:nth-last-of-type() {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .system__tender__group__pref__block {
    margin: 0;
    padding: 0;
    border: 0;
  }
  .system__tender__group__pref__block > h3 {
    display: flex;
    align-items: center;
    padding: 0 50px 0 30px;
    width: 100%;
    height: 50px;
    color: #fff !important;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.16em;
    background-color: #0072BC;
    pointer-events: none;
  }
}
@media screen and (min-width: 992px) {
  .tblWrap {
    margin: 30px auto;
    padding: 20px 30px;
    border: 1px solid #C0DBEC;
  }
}
.system__tender__group__tbl {
  margin-top: 20px;
  text-align: left;
  border-bottom: 1px solid #C0DBEC;
}
.system__tender__group__tbl + table {
  margin-top: 25px;
}
.system__tender__group__tbl th, .system__tender__group__tbl td {
  font-size: 13px;
  font-weight: 400;
  border-top: 1px solid #C0DBEC;
}
.system__tender__group__tbl th {
  padding: 8px 10px 8px 8px;
  width: 100px;
  text-align: right;
  background-color: #F1F6F9;
}
.system__tender__group__tbl td {
  padding: 8px 0 8px 10px;
  width: calc(100% - 100px);
  vertical-align: middle;
}

@media screen and (min-width: 992px) {
  .system__tender__group__tbl + table {
    margin-top: 32px;
  }
  .system__tender__group__tbl th, .system__tender__group__tbl td {
    font-size: 16px;
  }
  .system__tender__group__tbl th {
    padding: 3px 20px 3px 8px;
    width: 220px;
  }
  .system__tender__group__tbl td {
    padding: 3px 0 3px 30px;
    width: calc(100% - 220px);
  }
}
@media screen and (min-width: 768px) {
  .system__select__contents__box.selectBox_1 .t1 {
    text-align: center;
  }
}
dl.js-system-accordion dt {
  position: relative;
  cursor: pointer;
}
dl.js-system-accordion dt .icon_openclose {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
dl.js-system-accordion dt .icon_openclose::before, dl.js-system-accordion dt .icon_openclose::after {
  content: "";
  display: block;
  width: 20px;
  height: 4px;
  position: absolute;
}
dl.js-system-accordion dt .icon_openclose::before {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
dl.js-system-accordion dt .icon_openclose::after {
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
dl.js-system-accordion dt.open .icon_openclose {
  transform: translateY(-50%) rotate(45deg);
}

@media screen and (min-width: 992px) {
  dl.js-system-accordion dt {
    padding-right: 30px;
  }
  dl.js-system-accordion dt .icon_openclose {
    width: 16px;
    height: 16px;
    right: 18px;
  }
  dl.js-system-accordion dt .icon_openclose::before, dl.js-system-accordion dt .icon_openclose::after {
    width: 16px;
    height: 3px;
  }
}
[data-group="1"] {
  border: 1px solid #0072BC;
}
[data-group="1"] .system__tender__group__pref__anchor {
  display: none;
}
[data-group="1"] .pref_name {
  display: none;
}
@media screen and (min-width: 992px) {
  [data-group="1"] .pref_name {
    display: flex;
  }
}
[data-group="1"] dt {
  color: #0072BC;
}
[data-group="1"] dt .icon_openclose::before, [data-group="1"] dt .icon_openclose::after {
  background-color: #0072BC;
}
[data-group="1"] dt.open {
  background-color: #EFF9FF;
}
@media screen and (min-width: 992px) {
  [data-group="1"] dt:hover {
    background-color: #EFF9FF;
  }
}

[data-group="2"] {
  border: 1px solid #5B84E1;
}
[data-group="2"] .system__tender__group__pref__anchor {
  display: none;
}
[data-group="2"] .pref_name {
  display: none;
}
@media screen and (min-width: 992px) {
  [data-group="2"] .pref_name {
    display: flex;
  }
}
[data-group="2"] dt {
  color: #5B84E1;
}
[data-group="2"] dt .icon_openclose::before, [data-group="2"] dt .icon_openclose::after {
  background-color: #5B84E1;
}
[data-group="2"] dt.open {
  background-color: #F3F7FF;
}
@media screen and (min-width: 992px) {
  [data-group="2"] dt:hover {
    background-color: #F3F7FF;
  }
}

[data-group="3"] {
  border: 1px solid #619FEB;
}
[data-group="3"] dt {
  color: #619FEB;
}
[data-group="3"] dt .icon_openclose::before, [data-group="3"] dt .icon_openclose::after {
  background-color: #619FEB;
}
[data-group="3"] dt.open {
  background-color: #EFF6FF;
}
@media screen and (min-width: 992px) {
  [data-group="3"] dt:hover {
    background-color: #EFF6FF;
  }
}

[data-group="4"] {
  border: 1px solid #00ADAD;
}
[data-group="4"] dt {
  color: #00ADAD;
}
[data-group="4"] dt .icon_openclose::before, [data-group="4"] dt .icon_openclose::after {
  background-color: #00ADAD;
}
[data-group="4"] dt.open {
  background-color: #F1FFFF;
}
@media screen and (min-width: 992px) {
  [data-group="4"] dt:hover {
    background-color: #F1FFFF;
  }
}

[data-group="5"] {
  border: 1px solid #39B239;
}
[data-group="5"] dt {
  color: #39B239;
}
[data-group="5"] dt .icon_openclose::before, [data-group="5"] dt .icon_openclose::after {
  background-color: #39B239;
}
[data-group="5"] dt.open {
  background-color: #F1FFF1;
}
@media screen and (min-width: 992px) {
  [data-group="5"] dt:hover {
    background-color: #F1FFF1;
  }
}

[data-group="6"] {
  border: 1px solid #7EB80A;
}
[data-group="6"] dt {
  color: #7EB80A;
}
[data-group="6"] dt .icon_openclose::before, [data-group="6"] dt .icon_openclose::after {
  background-color: #7EB80A;
}
[data-group="6"] dt.open {
  background-color: #F8FFEB;
}
@media screen and (min-width: 992px) {
  [data-group="6"] dt:hover {
    background-color: #F8FFEB;
  }
}

[data-group="7"] {
  border: 1px solid #DBAF00;
}
[data-group="7"] dt {
  color: #DBAF00;
}
[data-group="7"] dt .icon_openclose::before, [data-group="7"] dt .icon_openclose::after {
  background-color: #DBAF00;
}
[data-group="7"] dt.open {
  background-color: #FFFBEC;
}
@media screen and (min-width: 992px) {
  [data-group="7"] dt:hover {
    background-color: #FFFBEC;
  }
}

[data-group="8"] {
  border: 1px solid #F27791;
}
[data-group="8"] dt {
  color: #F27791;
}
[data-group="8"] dt .icon_openclose::before, [data-group="8"] dt .icon_openclose::after {
  background-color: #F27791;
}
[data-group="8"] dt.open {
  background-color: #FFF3F6;
}
@media screen and (min-width: 992px) {
  [data-group="8"] dt:hover {
    background-color: #FFF3F6;
  }
}

[data-group="9"] {
  border: 1px solid #EC8E00;
}
[data-group="9"] dt {
  color: #EC8E00;
}
[data-group="9"] dt .icon_openclose::before, [data-group="9"] dt .icon_openclose::after {
  background-color: #EC8E00;
}
[data-group="9"] dt.open {
  background-color: #FFF9EF;
}
@media screen and (min-width: 992px) {
  [data-group="9"] dt:hover {
    background-color: #FFF9EF;
  }
}

[data-group="10"] {
  border: 1px solid #D651BD;
}
[data-group="10"] dt {
  color: #D651BD;
}
[data-group="10"] dt .icon_openclose::before, [data-group="10"] dt .icon_openclose::after {
  background-color: #D651BD;
}
[data-group="10"] dt.open {
  background-color: #FFF5FD;
}
@media screen and (min-width: 992px) {
  [data-group="10"] dt:hover {
    background-color: #FFF5FD;
  }
}

[data-group="11"] {
  border: 1px solid #8E58DB;
}
[data-group="11"] .system__tender__group__pref__anchor {
  display: none;
}
[data-group="11"] .pref_name {
  display: none;
}
@media screen and (min-width: 992px) {
  [data-group="11"] .pref_name {
    display: flex;
  }
}
[data-group="11"] dt {
  color: #8E58DB;
}
[data-group="11"] dt .icon_openclose::before, [data-group="11"] dt .icon_openclose::after {
  background-color: #8E58DB;
}
[data-group="11"] dt.open {
  background-color: #F7F2FF;
}
@media screen and (min-width: 992px) {
  [data-group="11"] dt:hover {
    background-color: #F7F2FF;
  }
}