.system{

	.page-header{
		padding: 50px 15px 0;
		height: 210px;
		background-image: url(../images/common/ph_bg_sp.jpg);
		background-size: cover;
	}

	&__header{
		padding: 36px 15px 20px;

		.inner{
			margin: 0 auto;
			padding: 0 0 10px;
			max-width: 1100px;
			border-bottom: 1px solid #C0DBEC;
		}
	}

	&__block{
		margin: 0;
		padding: 40px 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			width: 100%;
			max-width: 1100px;
		}
	}
}
@media screen and (min-width:768px){
	.system{
		.page-header{
			padding: 80px 15px 0;
			height: 320px;
			background-image: url(../images/common/ph_bg.jpg);
			background-size: cover;
		}

		&__header{
			padding: 80px 15px 28px;

			.inner{
				padding: 0 0 17px;
			}
		}
	}
}


//----------------------------------------------
@media screen and (min-width:992px){
	.system__intro{
		padding: 75px 15px;

		p{
			text-align: center;
		}
	}
}

//----------------------------------------------
.system__select{
	padding: 0 15px 20px;

	&__buttons{
		margin: 0 -15px 30px;
		padding: 0 15px;
		border-bottom: 1px solid #0072BC;

		.flexBox{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 -5px;
		}

		.flexItem{
			margin: 0;
			margin-bottom: -1px;
			padding: 0 5px;
			width: 33.3333%;
		}

		.selectBtn{
			padding: 14px 0;
			width: 100%;
			height: 100%;
			color: #FFF;
			font-size: 13px;
			font-weight: 500;
			text-align: center;
			line-height: 1.54;
			letter-spacing: 0;
			background-color: #0072BC;
			border: 1px solid #0072BC;
			border-bottom: 0;
			border-radius: 6px 6px 0 0;
			cursor: pointer;

			&.on{
				color: #0072BC;
				background-color: #FFF;
				pointer-events: none;
			}

			&:hover{
				color: #0072BC;
				background-color: #F1F6F9;
			}
		}
	}

	&__contents{

		&__box{
			display: none;

			&.open{
				display: block;
			}
		}
	}
}
@media screen and (min-width:768px){
	.system__select{
		&__buttons{
			margin: 0 0 60px;
		}
	}
}
@media screen and (min-width:992px){
	.system__select{
		margin: 0 0 60px;

		&__buttons{
			padding: 0 30px;
			position: relative;

			&::after{
				content: "";
				display: block;
				width: 100vw;
				height: 1px;
				border-bottom: 1px solid #0072BC;
				position: absolute;
				left: 50%;
				bottom: -1px;
				transform: translateX(-50%);
				z-index: -1;
			}

			.flexBox{
				margin: 0 -20px;
			}

			.flexItem{
				padding: 0 20px;
			}

			.selectBtn{
				padding: 17px 0;
				font-size: 18px;
				font-weight: 700;
			}
		}
	}
}

//----------------------------------------------
@media screen and (min-width:992px){
	.system__tender__map{
		margin: 50px auto 84px;
		position: relative;

		.img{
			margin: 0 auto;
			padding: 15px 0 160px;
			width: 100%;
			max-width: 845px;
			transform: translateX(-20px);
		}

		&__perf{

			dl{
				width: 186px;
				position: absolute;
				border-radius: 6px;
				overflow: hidden;
			}

			dt{
				padding: 16px 21px;
				font-size: 18px;
				font-weight: 500;
				text-align: center;
				line-height: 1;
				cursor: pointer;

				a{
					color: inherit;
					text-decoration: none;
					pointer-events: none;
				}

				&.open{

					.icon_openclose{
						transform: translateY(-50%) rotate(45deg);

						&::before, &::after{
							background-color: #707070 !important;
						}
					}
				}
			}

			dd{
				display: none;
				padding: 0 22px 7px;

				ul{
					margin-right: -30px;

					&::after{
						content: "";
						display: block;
						clear: both;
						height: 0;
					}
				}

				li{
					float: left;
					margin-right: 22px;
					line-height: 2.2;
				}

				a{
					color: #333;
				}
			}

			
			dl[data-group="1"]{
				width: 270px;
				left: 0;
				top: 0;

				dt{
					padding: 16px 37px;
				}
			}

			dl[data-group="2"]{
				width: 122px;
				left: 50%;
				top: 9.5%;

				dt{
					padding: 16px 31px;
				}
			}

			dl[data-group="3"]{
				left: 83%;
				top: 24.5%;
			}

			dl[data-group="4"]{
				left: 73.3%;
				top: 42.6%;
			}

			dl[data-group="5"]{
				width: 130px;
				left: 64%;
				top: 64.7%;
			}

			dl[data-group="6"]{
				left: 38.7%;
				top: 26.4%;
			}

			dl[data-group="7"]{
				width: 200px;
				left: 43%;
				top: 72%;
			}

			dl[data-group="8"]{
				width: 130px;
				left: 26.5%;
				top: 85.5%;
			}

			dl[data-group="9"]{
				left: 19.2%;
				top: 41%;
			}

			dl[data-group="10"]{
				left: 0%;
				top: 64%;
			}

			dl[data-group="11"]{
				width: 122px;
				left: 9.5%;
				top: 18.8%;
			}

		}
	}
}
//----------------------------------------------
.system__tender__contents{
	margin: 0 -15px;
	padding: 20px 0 0;
}
@media screen and (min-width:992px){
	.system__tender__contents{
		margin: 0 auto;
		padding: 0;
		width: 100%;
		max-width: 900px;
	}
}

//----------------------------------------------
.system__tender__group{

	&__wrap{
		overflow: hidden;
	}

	&__elem{
		margin: 10px -1px;
		border: 1px solid #0072BC;

		dt{
			display: flex;
			align-items: center;
			padding: 0 40px 0 20px;
			width: 100%;
			height: 55px;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.05em;

			&.open{

				.icon_openclose{
					transform: translateY(-50%) rotate(45deg);
				}
			}
		}

		dd{
			display: none;
			padding: 0;
			background-color: #EFEFEF;
		}
	}
}
#s1-2 {
	.system__tender__group {
		&__elem {
			dd {
				display: block;
			}
		}
	}
}
#s1-3 {
	.system__tender__group {
		&__elem {
			dd {
				display: block;
			}
		}
	}
}
@media screen and (min-width:992px){
	.system__tender__group{

		&__elem{
			display: none;
			margin: 50px 0;
			border: 0 !important;

			dt{
				padding: 0 50px 0 30px;
				height: 50px;
				color: #fff !important;
				letter-spacing: 0.16em;
				background-color: #0072BC;
				pointer-events: none;
				display: none;

				.icon_openclose{
					display: none;
				}
			}

			dd{
				display: block !important;
				padding: 20px 0 0;
				background: none;
			}
		}
	}
	#s1-2 {
		.system__tender__group {
			&__elem {
				display: block;
				margin: 0;

				.pref_name {
					display: none;
				}
			}
		}
	}
	#s1-3 {
		.system__tender__group {
			&__elem {
				display: block;
				margin: 0;

				.pref_name {
					display: none;
				}
			}
		}
	}
}


//----------------------------------------------
.system__tender__group__pref__anchor{
	display: flex;
	flex-wrap: wrap;
	padding: 10px 15px;
	background-color: #EFEFEF;

	li{
		margin-right: 20px;
	}

	a{
		color: #333;
		font-size: 14px;
	}
}
@media screen and (min-width:992px){
	.system__tender__group__pref__anchor{
		display: none;
	}
}

//----------------------------------------------
.system__tender__group__pref__block{
	margin: 20px 0;
	padding: 15px 15px;
	background-color: #fff;

	&:nth-of-type(1){
		margin-top: 0;
	}

	&:nth-last-of-type(){
		margin-bottom: 0;
	}
}
@media screen and (min-width:992px){
	.system__tender__group__pref__block{
		margin: 0;
		padding: 0;
		border: 0;

		& > h3{
			display: flex;
			align-items: center;
			padding: 0 50px 0 30px;
			width: 100%;
			height: 50px;
			color: #fff !important;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.16em;
			background-color: #0072BC;
			pointer-events: none;
		}
	}
}

//----------------------------------------------
@media screen and (min-width:992px){
	.tblWrap{
		margin: 30px auto;
		padding: 20px 30px;
    border: 1px solid #C0DBEC;
	}
}

//----------------------------------------------
.system__tender__group__tbl{
	margin-top: 20px;
	text-align: left;
	border-bottom: 1px solid #C0DBEC;

	& + table{
		margin-top: 25px;
	}

	th, td{
		font-size: 13px;
		font-weight: 400;
		border-top: 1px solid #C0DBEC;
	}

	th{
		padding: 8px 10px 8px 8px;
    width: 100px;
    text-align: right;
    background-color: #F1F6F9;
	}

	td{
		padding: 8px 0 8px 10px;
		width: calc( 100% - 100px );
		vertical-align: middle;
	}
}
@media screen and (min-width:992px){
	.system__tender__group__tbl{

		& + table{
			margin-top: 32px;
		}

		th, td{
			font-size: 16px;
		}

		th{
			padding: 3px 20px 3px 8px;
			width: 220px;
		}

		td{
			padding: 3px 0 3px 30px;
			width: calc( 100% - 220px );
		}
	}
}


//----------------------------------------------
@media screen and (min-width:768px){
	.system__select__contents__box.selectBox_1{

		.t1{
			text-align: center;
		}
	}
}


//----------------------------------------------
dl.js-system-accordion{

	dt{
		position: relative;
		cursor: pointer;

		.icon_openclose{
			width: 20px;
			height: 20px;
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);

			&::before, &::after{
				content: "";
				display: block;
				width: 20px;
				height: 4px;
				position: absolute;
			}
			&::before{
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&::after{
				right: 0;
				top: 50%;
				transform: translateY(-50%) rotate(90deg);
			}
		}

		&.open{

			.icon_openclose{
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
}
@media screen and (min-width:992px){
	dl.js-system-accordion{

		dt{
			padding-right: 30px;
			
			.icon_openclose{
				width: 16px;
				height: 16px;
				right: 18px;

				&::before, &::after{
					width: 16px;
					height: 3px;
				}
			}
		}
	}
}




//----------------------------------------------
[data-group="1"]{
	border: 1px solid #0072BC;

	.system__tender__group__pref__anchor{
		display: none;
	}
	.pref_name{
		display: none;
		@media screen and (min-width:992px){
			display: flex;
		}
	}

	dt{
		color: #0072BC;
		.icon_openclose{
			&::before, &::after{
				background-color: #0072BC;
			}
		}
		&.open{
			background-color: #EFF9FF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #EFF9FF;
			}
		}
	}
}

[data-group="2"]{
	border: 1px solid #5B84E1;

	.system__tender__group__pref__anchor{
		display: none;
	}
	.pref_name{
		display: none;
		@media screen and (min-width:992px){
			display: flex;
		}
	}

	dt{
		color: #5B84E1;
		.icon_openclose{
			&::before, &::after{
				background-color: #5B84E1;
			}
		}
		&.open{
			background-color: #F3F7FF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #F3F7FF;
			}
		}
	}
}

[data-group="3"]{
	border: 1px solid #619FEB;
	dt{
		color: #619FEB;
		.icon_openclose{
			&::before, &::after{
				background-color: #619FEB;
			}
		}
		&.open{
			background-color: #EFF6FF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #EFF6FF;
			}
		}
	}
}

[data-group="4"]{
	border: 1px solid #00ADAD;

	dt{
		color: #00ADAD;
		.icon_openclose{
			&::before, &::after{
				background-color: #00ADAD;
			}
		}
		&.open{
			background-color: #F1FFFF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #F1FFFF;
			}
		}
	}
}

[data-group="5"]{
	border: 1px solid #39B239;
	dt{
		color: #39B239;
		.icon_openclose{
			&::before, &::after{
				background-color: #39B239;
			}
		}
		&.open{
			background-color: #F1FFF1;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #F1FFF1;
			}
		}
	}
}

[data-group="6"]{
	border: 1px solid #7EB80A;
	dt{
		color: #7EB80A;
		.icon_openclose{
			&::before, &::after{
				background-color: #7EB80A;
			}
		}
		&.open{
			background-color: #F8FFEB;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #F8FFEB;
			}
		}
	}
}

[data-group="7"]{
	border: 1px solid #DBAF00;
	dt{
		color: #DBAF00;
		.icon_openclose{
			&::before, &::after{
				background-color: #DBAF00;
			}
		}
		&.open{
			background-color: #FFFBEC;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #FFFBEC;
			}
		}
	}
}

[data-group="8"]{
	border: 1px solid #F27791;
	dt{
		color: #F27791;
		.icon_openclose{
			&::before, &::after{
				background-color: #F27791;
			}
		}
		&.open{
			background-color: #FFF3F6;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #FFF3F6;
			}
		}
	}
}

[data-group="9"]{
	border: 1px solid #EC8E00;
	dt{
		color: #EC8E00;
		.icon_openclose{
			&::before, &::after{
				background-color: #EC8E00;
			}
		}
		&.open{
			background-color: #FFF9EF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #FFF9EF;
			}
		}
	}
}

[data-group="10"]{
	border: 1px solid #D651BD;
	dt{
		color: #D651BD;
		.icon_openclose{
			&::before, &::after{
				background-color: #D651BD;
			}
		}
		&.open{
			background-color: #FFF5FD;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #FFF5FD;
			}
		}
	}
}

[data-group="11"]{
	border: 1px solid #8E58DB;

	.system__tender__group__pref__anchor{
		display: none;
	}
	.pref_name{
		display: none;
		@media screen and (min-width:992px){
			display: flex;
		}
	}
	
	dt{
		color: #8E58DB;
		.icon_openclose{
			&::before, &::after{
				background-color: #8E58DB;
			}
		}
		&.open{
			background-color: #F7F2FF;
		}
		@media screen and (min-width:992px){
			&:hover{
				background-color: #F7F2FF;
			}
		}
	}
}